import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Search from "./search";

const searchStaticQuery = graphql`
  query {
    allErpItem {
      edges {
        node {
          id
          item_name
          slug
          creation
          item_code
          is_sales_item
					last_purchase_rate
          localImage {
						childImageSharp {
							gatsbyImageData(
								aspectRatio: 1.25,
								placeholder: TRACED_SVG,
								transformOptions: {fit: CONTAIN},
								backgroundColor: "transparent"
							)
						}
					}
        }
      }
    }
  }
`;

export default () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${searchStaticQuery}`}
    render={({ allErpItem }) => {
      return <Search allErpItem={allErpItem} />;
    }}
  />
);

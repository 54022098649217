/** @jsx jsx */
import { useContext, useState, useRef } from 'react';
import { Box, Flex, Text, Image, jsx, ThemeUIStyleObject, Button } from 'theme-ui';
import { animated, useSpring, useChain } from 'react-spring';
import { ModalContext } from '../../provider/modal-provider';
import useFreezeBodyScroll from '../../hooks/useFreezeBodyScroll';
import businessAdImage from '../../images/unlock-business.svg';
import logo from '../../images/logo.svg';
import { IoIosClose } from 'react-icons/io';
import { relative } from 'path';

const BusinessModal: React.FC<{ btnProps: any }> = ({ btnProps }) => {
	const { isBusinessAccountAdOpen, setIsBusinessAccountAdOpen } = useContext(ModalContext);
	const open = isBusinessAccountAdOpen;
	useFreezeBodyScroll(isBusinessAccountAdOpen);

	const cartBtnProps = useSpring({
		config: { mass: 1, tension: 800, friction: 50 },
		opacity: open ? 0 : 1,
		transform: open ? 'translateY(0)' : 'translateY(-100%)',
	});
	const cartOverlayRef = useRef();
	// @ts-ignore
	const { size, ...rest } = useSpring({
		ref: cartOverlayRef,
		config: { mass: 1, tension: 800, friction: 50 },
		from: {
			size: '100%',
			right: '0',
			opacity: 0,
			bottom: '0',
			borderRadius: 10,
		},
		to: {
			opacity: open ? 1 : 0,
			size: open ? '100%' : '100%',
			left: open ? '0' : '100%',
			bottom: open ? '0' : '10%',
			borderRadius: open ? 0 : 10,
		},
	});
	useChain(
		// @ts-ignore
		open ? [cartBtnProps, cartOverlayRef] : [cartOverlayRef, cartBtnProps],
		[0, open ? 0.1 : 0.2]
	);

	return (
		<Box className={open ? 'active' : ''} sx={styles.wrapper}>
			<animated.div
				style={{
					...rest,
					width: size,
					height: size,
					position: 'fixed',
					backgroundColor: 'white',
					pointerEvents: open ? 'all' : 'none',
					zIndex: 9999,
				}}
			>
				<Box sx={styles.modalInnerWrapper}>
					<Flex sx={{flexDirection: 'row'}}>
						<Flex sx={{flexDirection: 'column', flex: '2 1 0%', background: '#01beff3d', padding: '0 5vw', height:'100vh'}}>
							<Image sx={{flex: '1 1 0%', maxWidth: '250px', }}  src={logo} alt="meditec logo" />
							<Image sx={{flex: '4 2 0%', maxWidth: '500px', margin: 'auto'}} src={businessAdImage} alt="business create account" />
						</Flex>
						<Box sx={{flex: '3 1 0%', background: '#ffffff', }}>
							<Button sx={{position:'relative', top:'20px', right:'10px', float:'right', zIndex:999}} title="Close" variant="text" onClick={() => { setIsBusinessAccountAdOpen(false) }}>
								<IoIosClose />
							</Button>
							<Box sx={styles.rightHolder}>
								<Text as='p' sx={styles.headingText}>Cree hoy una cuenta de negocios</Text>
								<Text as='p' sx={styles.normalText}>Con un acceso en linea podrá:</Text>
								<ul>
									<li>Acceder a precios y disponiblidad del amplio catálogo de productos en venta</li>
									<li>Generar cotizaciones, órdenes de compra y acceso a su historial de pedidos</li>
									<li>Realizar el seguimiento a tús pedidos, asi cómo enviar recibos de pago</li>
								</ul>
								<Button
									sx={styles.button}
									onClick={ () => { setIsBusinessAccountAdOpen(false); }}
								>
									Crear Cuenta
								</Button>
							</Box>
						</Box>
					</Flex>
				</Box>
			</animated.div>
		</Box>
	);
};

const styles:{
	modalInnerWrapper: ThemeUIStyleObject,
	wrapper: ThemeUIStyleObject,
	cartText: ThemeUIStyleObject,
	price: ThemeUIStyleObject,
	notFound: ThemeUIStyleObject,
	headingText: ThemeUIStyleObject,
	button: ThemeUIStyleObject,
	rightHolder: ThemeUIStyleObject,
} = {
	rightHolder: {
		padding: '140px',
		fontSize: '25px',
		color: '#595959',
		'li': {
			paddingBottom: '18px'
		},
		'ul':{
			paddingTop: 10,
			paddingBottom: 10,
		}
	},
	headingText: {
		fontSize: '40px',
		fontWeight: '600',
		lineHeight: '120px'
	},
	button:{
		width: '70%',
		margin: 'auto',
		backgroundColor: 'green_medical',
		marginTop: '25px',
		fontSize: '22px',
		fontWeight: '600',
		borderRadius: 25,
		padding: 12
	},
	modalInnerWrapper:{
		maxWidth: '100vw',
		margin: '0 auto',
	},
	wrapper: {
		maxWidth: 960,
		margin: '0 auto',
		pointerEvents: 'none',
		width: 'calc(100% - 40px)',
		'&.active': {
			'&::after': {
				content: '""',
				display: 'block',
				width: '100vw',
				height: '100vh',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				position: 'fixed',
				left: 0,
				bottom: 0,
			},
		},
	},
	cartText: {
		alignItems: 'center',
		fontSize: '15px',
		svg: {
			marginRight: 15,
		},
	},
	price: {
		fontSize: '15px',
		fontWeight: 500,
	},
	notFound: {
		textAlign: 'center',
		paddingTop: ['25px', '50px'],
		paddingBottom: ['25px', '50px'],
		img: {
			marginBottom: 30,
		},
		h3: {
			fontSize: 2,
			color: 'primary',
			marginBottom: [12, 15],
		},
	},
};

export default BusinessModal;

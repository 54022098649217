/** @jsx jsx */
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { RichText } from 'prismic-reactjs';
import { Box, Text, jsx } from 'theme-ui';
import Container from '../../../container/container';
import SocialLinks from '../../../social-links/social-links';
import styles from './footer.styles';

const footerStaticQuery = graphql`
	query {
		prismicCommon {
			data {
				copyright_text {
					raw
				}
				social_links {
					social_link {
						url
					}
					social_type
				}
			}
		}
	}
`;

const getSocialData = (links:any) => {
	const data: any = [];
	links.forEach((link: any) => {
		data.push({ type: link.social_type, link: link.social_link.url });
	});
	return data;
};

const Footer: React.FC<{ fluid?: boolean }> = ({ fluid }) => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${footerStaticQuery}`}
		render={(data) => {
			const footerData = get(data, 'prismicCommon.data');
			const socialData = getSocialData(footerData.social_links);
			const currentYear = new Date().getFullYear();

			return (
				<Box as="footer" sx={styles.footer}>
					<Container fluid={fluid}>
						<Text className="copyright" sx={styles.copyright}>
							Copyright &#169; {currentYear} <Text style={{fontWeight: "bold"}}>{RichText.asText(footerData.copyright_text.raw)}</Text> - Todos los derechos reservados
						</Text>
						<SocialLinks items={socialData} />
					</Container>
				</Box>
			);
		}}
	/>
);

export default Footer;

// @jsx jsx 
import React, {
	useRef,
	useState,
	useEffect,
	useContext,
	useCallback,
} from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Flex, Button, Heading, Text, jsx, AspectRatio } from "theme-ui";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { LocalCartContext } from "../../provider/local-cart-provider";
import useOnClickOutside from "../../hooks/useOutsideClick";
import styles from "./product-card.style";

type PropsType = {
	title: string;
	thumbnail: any;
	item_code: string;
	availableForSale: boolean;
	listView?: boolean;
	price: number;
};

const ProductCard: React.FC<PropsType> = (product) => {
	const {
		title,
		thumbnail,
		availableForSale,
		listView = false,
		item_code,
		price
	} = product;

	const { products, add, update } = useContext(LocalCartContext);
	const counterRef = useRef(null!);
	const [showCounter, setShowCounter] = useState(false);
	useOnClickOutside(counterRef, () => setShowCounter(false));

	const productIndex = products.findIndex(
		(item) => item.variantId === item_code
	);

	const cartProduct = productIndex > -1 ? products[productIndex] : false;
	const cartProductQuantity = cartProduct ? cartProduct.quantity : 0;
	const [quantity, setQuantity] = useState(0);
	const [available, setAvailable] = useState(availableForSale);

	useEffect(() => {
		setQuantity(cartProductQuantity);
	}, [cartProductQuantity]);

	const handleQuantityChange = (quantity: number) => {
		update(item_code, quantity);
		if (quantity < 1) {
			setShowCounter(false);
		}
	};

	const handleAddToCart = () => {
		if (!availableForSale) {
			return false;
		}
		setShowCounter(true);
		if (quantity < 1) {
			const item = {
				title,
				thumbnail,
				quantity: 1,
				price,
				currency: "HNL",
				variantId: item_code,
			};
			add(item);
		}
	};

	const addClass = ["productCard"];
	if (quantity > 0 && showCounter) {
		addClass.push("active");
	}
	if (!availableForSale) {
		addClass.push("disabled");
	}

	return (
		<Box
			sx={styles.wrapper}
			className={addClass.join(" ")}
			onClick={() => handleAddToCart()}
		>
			<Box className="image" sx={styles.imageWrapper}>
				{thumbnail && <GatsbyImage image={thumbnail.childImageSharp.gatsbyImageData} alt={title} />}

				<Box
					className={quantity > 0 && showCounter ? "isActive" : ""}
					sx={styles.cart}
				>
					<Button
						variant="badge"
						sx={styles.cartBtn}
						className={quantity > 0 ? "active" : ""}
						title="Agregar a Cotización"
					>
						{quantity > 0 ? (
							quantity
						) : (
							<svg width="13" height="12" viewBox="0 0 13 12">
								<path
									d="M67.213,68.157H65.137l-2.953-3.949a.517.517,0,0,0-.829,0L58.4,68.157H56.326a1.056,1.056,0,0,0-1.056,1.054.987.987,0,0,0,.042.292l1.5,5.3A1.643,1.643,0,0,0,58.392,76h6.756a1.659,1.659,0,0,0,1.587-1.2l1.5-5.305a.509.509,0,0,0,.015-.077l.017-.16a.434.434,0,0,0,0-.05A1.056,1.056,0,0,0,67.213,68.157ZM61.77,65.386l2.072,2.771H59.7Zm0,7.957A1.224,1.224,0,1,1,63,72.118a1.223,1.223,0,0,1-1.227,1.224Z"
									transform="translate(-55.269 -64)"
									fill="#5a5a5a"
								/>
							</svg>
						)}
					</Button>

					{quantity ? (
						<Box ref={counterRef} sx={styles.cartCounter}>
							<Button
								title="Decrement"
								onClick={() => handleQuantityChange(quantity - 1)}
							>
								<IoIosRemove />
							</Button>
							<Box>{quantity}</Box>
							<Button
								title="Increment"
								onClick={() => handleQuantityChange(quantity + 1)}
							>
								<IoIosAdd />
							</Button>
						</Box>
					) : null}
				</Box>
			</Box>

			<Box className="content">
				<Flex sx={styles.meta}>
					{price && (
						<Text as="span" sx={styles.price}>
							<Text sx={styles.priceSymbol}>L</Text>{price}
						</Text>
					)}
					{!availableForSale && (
						<Box as="span" sx={styles.soldOut}>
							Sold Out
						</Box>
					)}
				</Flex>
				<Heading as="h4" sx={styles.title}>
					{title}
				</Heading>
			</Box>
		</Box>
	);
};

export default ProductCard;

/** @jsx jsx */
import { useRef, useEffect } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Box, Text, jsx } from 'theme-ui';
import { IoIosArrowDown } from 'react-icons/io';
import useWindowSize from '../../../../../hooks/useWindowSize';
import styles from './navbar.style';

const menuStaticQuery = graphql`
	query {
		allPrismicCategory {
			nodes {
				data {
					title {
						text
					}
					parent_category {
						uid
					}
				}
				uid
			}
		}
	}
`;

const getMenuData = (categories : readonly GatsbyTypes.PrismicCategory[]) => {
	const data: any = [];
	//fill up first parent
	categories.forEach((category) => {
		if (!category?.data?.parent_category?.uid) {
			const uid = category?.uid;
			const text = category?.data?.title?.text;
			if (uid && text) {
				data.push({ path: uid, title: text });
			}
		}
	});
	//fill up child
	categories.forEach((category) => {
		if (category?.data?.parent_category?.uid) {
			const parent = category?.data?.parent_category?.uid;
			const uid = category?.uid;
			const text = category?.data?.title?.text;
			const index = data.findIndex((item: any) => item.path === parent);
			if (index > -1) {
				if (data[index].submenu && data[index].submenu.length) {
					data[index].submenu.push({ path: uid, title: text });
				} else {
					data[index].submenu = [];
					data[index].submenu.push({ path: uid, title: text });
				}
			}
		}
	});
	return data;
};

const MainMenu: React.FC<{ onClick?: () => void; pathPrefix?: string }> = ({
	onClick,
	pathPrefix = '/categoria',
}) => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${menuStaticQuery}`}
		render={(data) => {
			const categories = data.allPrismicCategory.nodes;
			const menuData = getMenuData(categories);
			const mainMenu = useRef(null);
			const windowSize = useWindowSize();
			useEffect(() => {
				if (windowSize && windowSize < 1081) {
					const menu = mainMenu?.current?.children;
					for (let index = 0; index < menu.length; index++) {
						const element = menu[index];
						element.addEventListener('click', function () {
							element.classList.toggle('active');
						});
					}
				}
			}, [windowSize]);

			return (
				<Box className="mainMenu" as="ul" ref={mainMenu} sx={styles.mainMenu}>
					{menuData &&
						menuData.map((item: any) => (
							<Box
								as="li"
								className={`dropdown-items-${item?.submenu.length}`}
								key={item.title}
								sx={styles.mainMenuItem}
							>
								{item?.submenu ? (
									<Text as="span">{item.title}</Text>
								) : (
									<Link
										activeClassName="active"
										to={item.path}
										onClick={onClick}
									>
										{item.title}
									</Link>
								)}

								{item?.submenu && <IoIosArrowDown />}

								{/* if menu has submenu */}
								{item?.submenu && (
									<Box as="ul" sx={styles.submenu}>
										{item?.submenu.map((subItem: any) => (
											<Box as="li" key={`${item.title}-${subItem.title}`}>
												<Link
													activeClassName="active"
													to={`${pathPrefix}/${subItem.path}`}
													onClick={onClick}
												>
													{subItem.title}
												</Link>
											</Box>
										))}
									</Box>
								)}
							</Box>
						))}
				</Box>
			);
		}}
	/>
);

export default MainMenu;

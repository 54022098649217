/** @jsx jsx */
import React, { Fragment } from 'react';
import { Flex, Box, Grid, Heading, Text, jsx, Button } from 'theme-ui';
import ProductCard from '../product-card/product-card';
import ProductCardWithLink from '../product-card/with-link';
import styles from './product-grid.style';

type PropsType = {
	id?: string;
	close?: Function;
	isCategoryProduct?: boolean;
	withLink?: boolean;
	gridTitle?: string;
	products: any;
};

const ProductGrid: React.FC<PropsType> = ({
	id,
	close,
	gridTitle,
	products,
	withLink = true,
	isCategoryProduct = false,
}) => {
	const getPrice = (price: any) =>
		Intl.NumberFormat(undefined, {
			currency: price.currencyCode,
			minimumFractionDigits: 2,
			style: 'currency',
		}).format(parseFloat(price && price.amount ? price.amount : 0));
	return (
		<Box id={id} sx={styles.wrapper}>
			<Flex sx={styles.header}>
				{gridTitle && <Heading as="h3">{gridTitle}</Heading>}
				{close && (
					<Button variant="text" onClick={close}>
						Clear
					</Button>
				)}
			</Flex>
			{products ? (
				<Grid sx={styles.productGrid}>
					{products.map((product: any) => {
						const {
							id,
							item_name,
							slug,
							item_code,
							is_sales_item,
							localImage,
							last_purchase_rate
						} = isCategoryProduct ? product : product.node;
						return (
							<Fragment key={id}>
								{withLink ? (
									<ProductCardWithLink
										title={item_name}
										path={`/producto/${slug}`}
										item_code={item_code}
										availableForSale={is_sales_item}
										thumbnail={localImage}
										price={last_purchase_rate}
									/>
								) : (
									<ProductCard
										title={item_name}
										item_code={item_code}
										availableForSale={is_sales_item}
										thumbnail={localImage}
										price={last_purchase_rate}
									/>
								)}
							</Fragment>
						);
					})}
				</Grid>
			) : (
				<Text>No Products found!</Text>
			)}
		</Box>
	);
};

export default ProductGrid;
